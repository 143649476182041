var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.11.1-stage"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/nextjs'
import UAParser from 'ua-parser-js'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const TRACES_SAMPLE_RATE = Number(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE) || 0
const REPLAYS_SAMPLE_RATE = Number(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_SAMPLE_RATE) || 0
const REPLAYS_ONERROR_SAMPLE_RATE =
  Number(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ONERROR_SAMPLE_RATE) || 0
const NORMALIZE_DEPTH = Number(process.env.NEXT_PUBLIC_SENTRY_NORMALIZE_DEPTH) || 3

const IOS = 'iOS'
const ANDROID = 'Android'
const IOS_MIN_VERSION = 15
const ANDROID_MIN_VERSION = 9

export const checkIsOldDevices = () => {
  const uaParser = new UAParser()
  const userAgent = uaParser.setUA(navigator.userAgent)

  const deviceOS = userAgent && userAgent.getOS()
  const deviceOSVersion = deviceOS && deviceOS.version
  const deviceOSName = deviceOS && deviceOS.name

  const parsedOSVersion = parseFloat(deviceOSVersion || '')

  return (
    (deviceOSName === IOS && parsedOSVersion < IOS_MIN_VERSION) ||
    (deviceOSName === ANDROID && parsedOSVersion < ANDROID_MIN_VERSION)
  )
}

const filterEventsBeforeSend = (event) => {
  if (checkIsOldDevices()) {
    return null
  }

  return event
}

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  sampleRate: 1,
  tracesSampleRate: TRACES_SAMPLE_RATE,
  replaysSessionSampleRate: REPLAYS_SAMPLE_RATE,
  replaysOnErrorSampleRate: REPLAYS_ONERROR_SAMPLE_RATE,

  beforeSend: (event) => filterEventsBeforeSend(event),

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: process.env.APP_VERSION,
  environment: process.env.SENTRY_ENV,
  initialScope: {
    tags: {
      'build.date': process.env.APP_BUILD_DATE,
    },
  },
  integrations: [new ExtraErrorDataIntegration()],
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // fb/insta browser related issue https://stackoverflow.com/questions/72488297/referenceerror-getvaluesofautofillinputs-cant-find-variable-paymentautofillco
    'PaymentAutofillConfig',
    '_reportEvent is not defined',
    // paypal integration dependency
    'zoid destroyed all components',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  normalizeDepth: NORMALIZE_DEPTH,
})

const { Replay } = await import('@sentry/browser')
Sentry.addIntegration(new Replay())
